<template>
  <v-card>
    <v-breadcrumbs :items="breadcrumbs">
      <template v-slot:divider>
        <v-icon>mdi-forward</v-icon>
      </template>
    </v-breadcrumbs>
    <h4 class="text-center py-4">{{ system.systemName }}</h4>
    <v-btn color="cyan lighten-1 mr-2 ml-2" dark @click="changeitemType('all')"
      >All</v-btn
    >
    <v-btn color="green lighten-1 mr-2 " dark @click="changeitemType('checked')"
      >checked</v-btn
    >
    <v-btn
      color="yellow darken-4 mr-2 "
      dark
      @click="changeitemType('not_checked')"
      >not checked
    </v-btn>
    <v-card-title>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>
    <v-data-table
      :headers="headers"
      :items="items"
      :options.sync="options"
      :server-items-length="total"
      :search="search"
      :footer-props="{
        'items-per-page-options': itemsPerPage
      }"
      class="elevation-1 text-center"
    >
      <template v-slot:item.traceback="{ item }">
        <v-dialog
          v-model="dialog"
          scrollable
          max-width="524px"
          max-higth="800px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="gray darken-1 "
              dark
              x-small
              v-bind="attrs"
              v-on="on"
              @click="openTraceback(item.traceback)"
            >
              Open Traceback
            </v-btn>
          </template>
          <v-card>
            <v-card-title>Traceback</v-card-title>
            <v-divider></v-divider>
            <v-card-text style="height: 300px;">
              <pre>{{ traceback }}</pre>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
              <v-btn color="blue darken-1" text @click="dialog = false">
                Close
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </template>
      <template v-slot:item.action="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-bind="attrs"
              v-on="on"
              v-if="item.checked"
              color="yellow darken-4"
              class="m-2"
              @click="changeCheckedStatus(item, false)"
            >
              mdi-alert-circle-check
            </v-icon>
            <v-icon
              v-bind="attrs"
              v-on="on"
              v-else
              color="green lighten-1"
              class="mr-20"
              @click="changeCheckedStatus(item, true)"
            >
              mdi-bug-check
            </v-icon>
          </template>
          <span v-if="item.checked">uncheck error</span>
          <span v-else>check error</span>
        </v-tooltip>
        |
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-bind="attrs"
              v-on="on"
              color="red darken-2"
              @click="deleteItem(item)"
            >
              mdi-delete
            </v-icon>
          </template>
          <span>delete</span>
        </v-tooltip>
      </template>
      <template v-slot:item.is_auth="{ item }">
        <v-tooltip left v-if="item.is_auth">
          <template v-slot:activator="{ on, attrs }">
            <v-icon color="green lighten-1" v-bind="attrs" v-on="on" dark>
              mdi-check-circle
            </v-icon>
          </template>
          <span>auth error</span>
        </v-tooltip>
        <v-tooltip left v-else>
          <template v-slot:activator="{ on, attrs }">
            <v-icon color="yellow darken-4" v-bind="attrs" v-on="on" dark>
              mdi-close-circle
            </v-icon>
          </template>
          <span>basic error</span>
        </v-tooltip>
      </template>
      <template v-slot:item.checked="{ item }">
        <v-tooltip left v-if="item.checked">
          <template v-slot:activator="{ on, attrs }">
            <v-icon color="green lighten-1" v-bind="attrs" v-on="on" dark>
              mdi-check-circle
            </v-icon>
          </template>
          <span>checked error</span>
        </v-tooltip>
        <v-tooltip left v-else>
          <template v-slot:activator="{ on, attrs }">
            <v-icon color="yellow darken-4" v-bind="attrs" v-on="on" dark>
              mdi-close-circle
            </v-icon>
          </template>
          <span>not checked error</span>
        </v-tooltip>
      </template>
      <template v-slot:item.sended="{ item }">
        <v-tooltip left v-if="item.sended">
          <template v-slot:activator="{ on, attrs }">
            <v-icon color="green lighten-1" v-bind="attrs" v-on="on" dark>
              mdi-check-circle
            </v-icon>
          </template>
          <span>sended error</span>
        </v-tooltip>
        <v-tooltip left v-else>
          <template v-slot:activator="{ on, attrs }">
            <v-icon color="yellow darken-4" v-bind="attrs" v-on="on" dark>
              mdi-close-circle
            </v-icon>
          </template>
          <span>not sended error</span>
        </v-tooltip>
      </template>
      <!-- <template v-slot:no-data>
        <v-btn color="primary" @click="initialize">
          Reset
        </v-btn>
      </template> -->
    </v-data-table>
    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card>
        <v-card-title class="headline"
          >Are you sure you want to delete -
          <b>'{{ deletedItemName }}'</b> error?</v-card-title
        >
        <v-card-actions>
          <v-btn color="blue darken-1" dark @click="closeDelete">Cancel</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="red lighten-1" dark @click="deleteItemConfirm"
            >OK</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import store from '@/store/index'
import ErrorService from '@/services/ErrorService.js'
import { paginationMixin } from '@/mixins/PaginationMixin.js'

export default {
  mixins: [paginationMixin],
  created() {
    this.setBreadcrumbs([
      { text: 'Home', to: { path: '/' } },
      {
        text: ':systems'
      },
      { text: ':system' } // placeholder
    ])
    this.replaceBreadcrumb({
      find: ':system',
      replace: {
        text: `${this.system.systemName} errors`,
        to: {
          name: 'systemErrors',
          params: { system_id: this.system.systemId }
        },
        disabled: true
      }
    })
    this.replaceBreadcrumb({
      find: ':systems',
      replace: {
        text: this.system.systemTypeName,
        to: {
          name: 'systemsByType',
          params: {
            system_type: this.system.systemType,
            TypeName: this.system.systemTypeName
          }
        }
      }
    })
  },
  data() {
    return {
      dialog: false,
      traceback: '',
      headers: [
        {
          text: 'Name',
          align: 'center',
          filterable: false,
          value: 'name'
        },
        {
          text: 'Title',
          align: 'center',
          value: 'title'
        },
        { text: 'Traceback', value: 'traceback' },
        { text: 'DateTime', value: 'date_time' },
        { text: 'Error counter', value: 'error_counter' },
        { text: 'Is auth', value: 'is_auth' },
        { text: 'Checked', value: 'checked' },
        { text: 'Sended', value: 'sended' },
        { text: 'Actions', value: 'action', sortable: false }
      ]
    }
  },
  methods: {
    changeitemType(type) {
      this.$router
        .push({
          name: 'systemErrors',
          params: { systemId: this.systemId },
          query: { type: type }
        })
        .catch(() => {})
    },
    openTraceback(payload) {
      this.traceback = payload
      this.dialog = true
    },
    getDataFromAPI(type, offset) {
      // console.log(this.options)
      return new Promise((resolve, reject) => {
        const { sortBy, sortDesc, page, itemsPerPage } = this.options
        let items = []
        type = type !== undefined ? type : this.itemType
        const query = this.$route.query
        let total = 0
        let sort_by = sortBy.length == 1 ? sortBy[0] : 'date_time'
        let sort = sortDesc.length && sortDesc[0] == 1 ? -1 : 1
        offset = offset !== 0 ? itemsPerPage * (page - 1) : 0
        ErrorService.getErrors(
          this.$route.params.system_id,
          itemsPerPage,
          offset || 0,
          sort_by,
          sort,
          type,
          this.search
        )
          .then(response => {
            // console.log(response.data.results)
            items = response.data.results
            total = response.data.count
            resolve({ items, total })
          })
          .catch(error => {
            console.log(error)
            store.dispatch('setLoader', false)
          })
      })
    },
    deleteItemConfirm() {
      const index = this.items.indexOf(this.DeleteItem)
      ErrorService.deleteError(this.DeleteItem._id).then(() => {
        this.items.splice(index, 1)
        this.closeDelete()
        const notification = {
          type: 'success',
          message: `${this.DeleteItem.title} deleted.`
        }
        this.$store.dispatch('error/getTotalNotCheckedErrors')
        this.$store.dispatch('notification/add', notification, { root: true })
        this.getDataFromAPI(this.itemType, 0).then(data => {
          // console.log(data.items)
          this.items = data.items
          this.total = data.total
        })
      })
    },
    changeCheckedStatus(item, status) {
      ErrorService.checkCheckedStatus(item._id, status).then(() => {
        this.$store.dispatch('error/getTotalNotCheckedErrors')
        this.getDataFromAPI(this.itemType, 0).then(data => {
          // console.log(data.items)
          this.items = data.items
          this.total = data.total
        })
      })
    }
  },
  computed: {
    itemType() {
      return this.$route.query.type || 'all'
    }
  }
}
</script>

<style scoped></style>
